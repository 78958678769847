.about-company {
  padding: 60px 20px;
  text-align: center;
  background: linear-gradient(45deg, rgba(224, 247, 250, 0.9), rgba(255, 236, 179, 0.9));
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;

  &__title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  &__description {
    font-size: 1.2rem;
    margin-bottom: 40px;
    color: #666;
  }

  &__additional-info {
    font-size: 1rem;
    color: #777;
    margin: 1rem 0;
    line-height: 1.6;
  }

  &__values {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
  }

  .value-item {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 280px;
    transition: transform 0.3s, box-shadow 0.3s;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
    }

    .value-icon {
      font-size: 3rem;
      margin-bottom: 10px;
      color: #3498db;
    }

    .value-title {
      font-size: 1.5rem;
      margin: 10px 0;
      color: #333;
    }

    .value-description {
      font-size: 1rem;
      color: #777;
    }
  }
}

// Медиа-запросы для адаптивности
@media (max-width: 1024px) {
  .about-company {
    padding: 50px 15px;

    &__title {
      font-size: 2.2rem;
    }

    &__description {
      font-size: 1.1rem;
    }

    &__values .value-item {
      width: 250px;

      .value-icon {
        font-size: 2.8rem;
      }

      .value-title {
        font-size: 1.4rem;
      }

      .value-description {
        font-size: 0.95rem;
      }
    }
  }
}

@media (max-width: 768px) {
  .about-company {
    padding: 40px 10px;

    &__title {
      font-size: 2rem;
    }

    &__description {
      font-size: 1rem;
    }

    &__values .value-item {
      width: 220px;
      padding: 15px;

      .value-icon {
        font-size: 2.5rem;
      }

      .value-title {
        font-size: 1.3rem;
      }

      .value-description {
        font-size: 0.9rem;
      }
    }
  }
}

@media (max-width: 480px) {
  .about-company {
    padding: 30px 8px;

    &__title {
      font-size: 1.8rem;
    }

    &__description {
      font-size: 0.95rem;
    }

    &__values .value-item {
      width: 100%; // Использование полной ширины на очень маленьких экранах
      padding: 10px;

      .value-icon {
        font-size: 2.2rem;
      }

      .value-title {
        font-size: 1.2rem;
      }

      .value-description {
        font-size: 0.85rem;
      }
    }
  }
}
