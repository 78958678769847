$order-form-width: 500px;
$color-primary: #2b6cb0;
$color-selected: #90cdf4;
$color-hover: #bee3f8;
$color-background: #f7fafc;

.form-wrapper {
  max-height: 90vh; // Немного уменьшаем максимальную высоту
  overflow-y: auto;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.order-cleaning-form {
  max-width: $order-form-width;
  padding: 15px;
  background-color: $color-background;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  width: 100%;
  max-height: 80vh; // Уменьшенная высота формы
  overflow-y: auto;
  margin: 10px 0; // Отступ сверху и снизу для дополнительного пространства

  h2 {
    text-align: center;
    margin-bottom: 15px;
    color: #333;
    font-size: 22px; // Слегка уменьшаем размер шрифта
  }

  label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    color: #4a5568;

    input,
    textarea {
      width: 100%;
      padding: 10px;
      margin-top: 5px;
      border: 1px solid #e2e8f0;
      border-radius: 8px;
      font-size: 15px;
      transition: border-color 0.3s;

      &:focus {
        border-color: $color-primary;
        outline: none;
      }
    }
  }

  .service-selection-toggle {
    margin-bottom: 15px;
    text-align: center;

    button {
      background-color: $color-primary;
      color: white;
      padding: 8px 18px;
      border-radius: 8px;
      border: none;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: darken($color-primary, 10%);
      }
    }
  }

  .service-selection {
    margin-bottom: 15px;
    max-height: 250px; // Уменьшенная высота списка услуг
    overflow-y: auto;

    .service-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;

      .service-card {
        padding: 12px;
        background-color: white;
        border: 1px solid #cbd5e0;
        border-radius: 8px;
        cursor: pointer;
        transition: background-color 0.3s, box-shadow 0.3s, transform 0.2s;
        text-align: center;
        font-size: 15px;
        color: #2d3748;

        &:hover {
          background-color: $color-hover;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
          transform: scale(1.02);
        }

        &.selected {
          background-color: $color-selected;
          border-color: $color-primary;
          box-shadow: 0 0 10px rgba(144, 205, 244, 0.5);
          color: #2d3748;
        }
      }
    }
  }

  .submit-button {
    width: 100%;
    padding: 10px;
    background-color: $color-primary;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 15px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 15px;

    &:hover {
      background-color: darken($color-primary, 10%);
    }
  }

  @media (max-width: 768px) {
    max-width: 90%;
    padding: 10px;

    h2 {
      font-size: 18px;
    }

    label {
      font-size: 14px;

      input,
      textarea {
        font-size: 14px;
      }
    }

    .service-selection .service-list {
      grid-template-columns: 1fr;
      gap: 8px;
    }

    .submit-button {
      font-size: 14px;
      padding: 10px;
    }
  }

  @media (max-width: 480px) {
    max-width: 100%;
    padding: 8px;

    h2 {
      font-size: 16px;
      margin-bottom: 10px;
    }

    label {
      font-size: 12px;

      input,
      textarea {
        font-size: 14px;
        padding: 8px;
      }
    }

    .service-selection-toggle button,
    .submit-button {
      padding: 8px;
      font-size: 14px;
    }
  }
}
