.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); // Полупрозрачный фон
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; // Обеспечиваем отображение поверх других элементов
  
    &__content {
      background-color: #fff; // Белый фон для содержимого
      border-radius: 10px; // Скругленные углы
      padding: 20px; // Отступы
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); // Тень
      text-align: center;
    }
  
    &__close-button {
      background-color: #ff6b6b; // Цвет кнопки закрытия
      color: white; // Цвет текста кнопки
      border: none; // Убираем границу
      border-radius: 5px; // Закругление углов
      padding: 10px 20px; // Внутренние отступы
      cursor: pointer; // Указатель при наведении
      margin-top: 15px; // Отступ сверху
    }
  }
  