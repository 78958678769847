.consultation-form {
    max-width: 500px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

    &__title {
        font-size: 24px;
        margin-bottom: 20px;
        color: #333;
        text-align: center;
    }

    &__label {
        display: block;
        margin-bottom: 15px;
        font-size: 14px;
        color: #555;
    }

    &__input,
    &__textarea {
        width: 100%;
        padding: 10px;
        margin-top: 5px;
        border: 1px solid #ccc;
        border-radius: 6px;
        font-size: 16px;
        transition: border-color 0.3s, box-shadow 0.3s;

        &:focus {
            border-color: #3498db;
            box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);
            outline: none;
        }
    }

    &__textarea {
        height: 100px;
        resize: vertical;
    }

    &__buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }

    &__submit,
    &__close {
        padding: 12px 20px;
        font-size: 16px;
        cursor: pointer;
        border: none;
        border-radius: 8px;
        transition: background-color 0.3s;
    }

    &__submit {
        width: 100%;
        background-color: #3498db;
        color: white;

        &:hover {
            background-color: #2980b9;
        }
    }

    &__close {
        background-color: #e74c3c;
        color: white;

        &:hover {
            background-color: #c0392b;
        }
    }

    // Адаптивные стили
    @media (max-width: 768px) {
        max-width: 90%;
        padding: 15px;

        &__title {
            font-size: 20px;
        }

        &__input,
        &__textarea {
            font-size: 14px;
        }

        &__submit,
        &__close {
            font-size: 14px;
            padding: 10px 15px;
        }
    }

    @media (max-width: 480px) {
        max-width: 100%;
        padding: 10px;

        &__title {
            font-size: 18px;
        }

        &__label {
            font-size: 12px;
        }

        &__input,
        &__textarea {
            font-size: 14px;
            padding: 8px;
        }

        &__buttons {
            flex-direction: column;
            gap: 10px;
            align-items: center;
        }

        &__submit,
        &__close {
            width: 100%;
            font-size: 14px;
            padding: 10px;
        }
    }
}
