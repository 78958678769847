.about-page {
  padding: 40px;
  background: linear-gradient(45deg, #e0f7fa, #ffecb3);
  box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.1); // Внутренняя тень для глубины

  h1, h2 {
      color: #333;
  }

  p {
      color: #666;
  }

  .about-header {
      text-align: center;
      margin-bottom: 40px;
  }

  section {
      margin-bottom: 30px;
      padding: 20px;
      background-color: rgba(255, 255, 255, 0.9); // Полупрозрачный белый
      border-radius: 8px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
}
