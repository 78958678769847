.cleaning-cost-calculator {
  padding: 2rem;
  background: linear-gradient(to right, #e0f7fa, #b2ebf2); // Градиентный фон
  border-radius: 15px; // Закругленные углы
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); // Тень
  text-align: center;
  max-width: 500px; // Максимальная ширина
  margin: 2rem auto; // Центровка по горизонтали

  h2 {
    color: #00796b;
    margin-bottom: 1.5rem;
    font-size: 2rem; // Размер заголовка
    font-weight: 700; // Жирный шрифт
  }

  .calculator-inputs {
    display: flex; // Используем флекс для равномерного распределения
    flex-direction: column; // Вертикальная компоновка
    align-items: center; // Центрируем содержимое
    margin-bottom: 1.5rem;

    label {
      width: 100%; // Ширина меток
      margin: 0.5rem 0; // Равномерный отступ
      font-weight: 600; // Жирный шрифт
      color: #004d40; // Цвет меток
      text-align: left; // Выравнивание текста влево

      input,
      select {
        padding: 0.75rem; // Увеличенные внутренние отступы
        margin-top: 0.5rem;
        border: 1px solid #00796b; // Цвет границы
        border-radius: 10px; // Закругленные углы
        width: 100%; // Ширина на 100%
        max-width: 400px; // Максимальная ширина для удобства
        font-size: 1rem; // Размер шрифта
        transition: border-color 0.3s; // Плавный переход

        &:focus {
          border-color: #004d40; // Цвет границы при фокусе
          outline: none; // Убираем стандартный обвод
        }
      }
    }

    button {
      padding: 0.75rem 1.5rem;
      background-color: #00796b; // Цвет кнопки
      color: white; // Цвет текста кнопки
      border: none; // Без границ
      border-radius: 10px; // Закругленные углы
      cursor: pointer; // Курсор при наведении
      transition: background-color 0.3s, transform 0.2s; // Плавные переходы
      margin-top: 1rem; // Отступ сверху для кнопки

      &:hover {
        background-color: #004d40; // Цвет кнопки при наведении
        transform: translateY(-2px); // Эффект приподнимания
      }

      &:active {
        transform: translateY(0); // Эффект нажатия
      }
    }
  }

  .calculator-result {
    h3 {
      color: #004d40; // Цвет текста результата
      font-weight: 700; // Жирный шрифт
      font-size: 1.5rem; // Увеличенный размер для результата
      margin-top: 1.5rem; // Отступ сверху
    }
  }
}

// Медиа-запросы для адаптивности
@media (max-width: 1024px) {
  .cleaning-cost-calculator {
    padding: 1.5rem; // Уменьшение отступов

    h2 {
      font-size: 1.8rem; // Уменьшение размера заголовка
    }

    .calculator-inputs {
      button {
        padding: 0.65rem 1.2rem; // Уменьшение размеров кнопки
      }

      input,
      select {
        font-size: 0.95rem; // Уменьшение размера шрифта
      }
    }
  }
}

@media (max-width: 768px) {
  .cleaning-cost-calculator {
    padding: 1rem; // Уменьшение отступов

    h2 {
      font-size: 1.6rem; // Уменьшение размера заголовка
    }

    .calculator-inputs {
      button {
        padding: 0.6rem 1rem; // Уменьшение размеров кнопки
      }

      input,
      select {
        font-size: 0.9rem; // Уменьшение размера шрифта
      }
    }
  }
}

@media (max-width: 480px) {
  .cleaning-cost-calculator {
    padding: 0.5rem; // Уменьшение отступов

    h2 {
      font-size: 1.4rem; // Уменьшение размера заголовка
    }

    .calculator-inputs {
      button {
        padding: 0.5rem; // Уменьшение размеров кнопки
      }

      input,
      select {
        font-size: 0.85rem; // Уменьшение размера шрифта
      }
    }
  }
}
