*,
*::before,
*::after {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.hero {
  position: relative;
  width: 100vw;
  height: 70vh; // Высота секции
  overflow: hidden;

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    z-index: 1;
    padding: 0 20px;

    @media (max-width: 1024px) {
      padding: 0 15px;
    }
    @media (max-width: 768px) {
      padding: 0 10px;
    }
    @media (max-width: 480px) {
      padding: 0 5px;
    }

    .content-container {
      background-color: rgba(0, 0, 0, 0.6);
      border: 2px solid #ff6b6b;
      border-radius: 10px;
      padding: 20px;

      @media (max-width: 1024px) {
        padding: 15px;
      }
      @media (max-width: 768px) {
        padding: 10px;
      }
      @media (max-width: 480px) {
        padding: 5px;
      }
    }

    .hero__buttons {
      display: flex;
      gap: 20px;
      justify-content: center;
      margin-top: 20px;

      @media (max-width: 768px) {
        flex-direction: column; // Вертикальное расположение кнопок на маленьких экранах
        gap: 10px;
        margin-top: 15px;
      }
    }

    .hero__cta-button {
      background-color: #ff6b6b;
      color: #fff;
      border: none;
      border-radius: 5px;
      padding: 15px 30px;
      font-size: 1.5rem;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #ff4f4f;
      }

      @media (max-width: 1024px) {
        padding: 12px 25px;
        font-size: 1.3rem;
      }
      @media (max-width: 768px) {
        padding: 10px 20px;
        font-size: 1rem;
      }
      @media (max-width: 480px) {
        padding: 8px 15px;
        font-size: 0.9rem;
      }
    }

    .hero__cta-button--consultation {
      background-color: #28a745;

      &:hover {
        background-color: #218838;
      }
    }
  }

  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw; // Занимает всю ширину экрана
    height: 100%; // Высота секции
    object-fit: cover;
    z-index: 0;
  }

  .hero__title {
    font-size: 3rem;
    margin: 0 0 20px;

    @media (max-width: 1024px) {
      font-size: 2.5rem;
      margin-bottom: 15px;
    }
    @media (max-width: 768px) {
      font-size: 2rem;
      margin-bottom: 10px;
    }
    @media (max-width: 480px) {
      font-size: 1.75rem;
      margin-bottom: 8px;
    }
  }

  .hero__description {
    font-size: 1.5rem;
    margin: 0 0 30px;

    @media (max-width: 1024px) {
      font-size: 1.25rem;
      margin-bottom: 20px;
    }
    @media (max-width: 768px) {
      font-size: 1rem;
      margin-bottom: 15px;
    }
    @media (max-width: 480px) {
      font-size: 0.9rem;
      margin-bottom: 10px;
    }
  }
}
