.contact-info {
    background-color: #fff; // Белый фон
    border: 1px solid #ddd; // Легкая граница
    border-radius: 15px; // Скругленные углы
    padding: 40px; // Отступы
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15); // Легкая тень
    margin: 30px 0;
  
    h2 {
      font-size: 2.5rem; // Размер заголовка
      color: #8e44ad; // Фиолетовый цвет
      text-align: center; // Центрирование заголовка
      margin-bottom: 25px; // Отступ снизу
      font-family: 'Georgia', serif; // Шрифт с засечками
    }
  
    .contact-info__details {
      display: flex; // Горизонтальное выравнивание
      flex-direction: column; // Вертикальное расположение
      align-items: flex-start; // Выравнивание по левому краю
      margin-bottom: 25px; // Отступ снизу
  
      .contact-info__item {
        display: flex; // Выравнивание
        align-items: center; // Вертикальное выравнивание
        background-color: #f2f2f2; // Светло-серый фон для элементов
        border-radius: 10px; // Скругленные углы
        padding: 15px; // Отступы
        margin-bottom: 15px; // Отступ между элементами
        width: 100%; // Полная ширина
  
        .contact-info__icon {
          font-size: 2rem; // Размер иконки
          color: #8e44ad; // Цвет иконки
          margin-right: 15px; // Отступ между иконкой и текстом
        }
  
        p {
          margin: 0; // Убираем отступы
          color: #333; // Цвет текста
        }
  
        .contact-info__title {
          font-weight: bold; // Жирный шрифт
          color: #333; // Темный цвет
        }
        
        .contact-info__phone,
        .contact-info__email,
        .contact-info__address {
          font-size: 1.1rem; // Размер текста
          color: #666; // Цвет текста
        }
      }
    }
  
    .contact-info__additional {
      text-align: center; // Центрирование текста
      margin-bottom: 25px; // Отступ снизу
  
      h3 {
        font-size: 1.6rem; // Размер заголовка
        color: #8e44ad; // Цвет заголовка
        margin-bottom: 15px; // Отступ снизу
      }
  
      p {
        margin: 5px 0; // Отступы
        color: #666; // Цвет текста
      }
    }
  
    .contact-info__social {
      text-align: center; // Центрирование текста
  
      h3 {
        font-size: 1.6rem; // Размер заголовка
        color: #8e44ad; // Цвет заголовка
        margin-bottom: 15px; // Отступ снизу
      }
  
      a {
        display: inline-block; // Выравнивание
        margin: 0 12px; // Отступы
        color: #8e44ad; // Цвет ссылок
        text-decoration: none; // Убираем подчеркивание
        transition: color 0.3s; // Плавный переход цвета
  
        &:hover {
          color: #732d91; // Цвет при наведении
        }
      }
    }
  }
  