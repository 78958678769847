.service-list {
  background: linear-gradient(to right, #e6f7ff, #f9f9f9); // Градиентный фон
  padding: 4rem 1rem; // Отступы
  text-align: center; // Центрирование текста
  color: #333; // Цвет текста

  h2 {
    font-size: 2.5rem; // Размер заголовка
    color: #3498db; // Цвет заголовка
    margin-bottom: 2rem; // Отступ снизу
    font-weight: bold; // Жирный текст
    text-transform: uppercase; // Заглавные буквы
  }

  .service-cards {
    display: flex; // Используем Flexbox для компоновки карточек
    flex-wrap: wrap; // Позволяем перенос карточек на новую строку
    justify-content: center; // Центрирование карточек

    .service-card {
      background: #ffffff; // Белый фон для карточек
      border: 1px solid #3498db; // Граница с цветом компании
      border-radius: 15px; // Закругленные углы
      padding: 2rem; // Отступы внутри карточки
      margin: 1rem; // Отступы между карточками
      width: 300px; // Фиксированная ширина карточки
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); // Легкая тень для глубины
      transition: transform 0.3s, box-shadow 0.3s; // Анимация при наведении

      &:hover {
        transform: translateY(-5px); // Подъем карточки при наведении
        box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2); // Увеличение тени
      }

      .service-icon {
        font-size: 2rem; // Размер иконки
        color: #3498db; // Цвет иконки
        margin-bottom: 1rem; // Отступ снизу от иконки
      }

      h3 {
        font-size: 1.5rem; // Размер заголовка услуги
        color: #333; // Цвет заголовка
        margin-bottom: 0.5rem; // Отступ снизу
      }

      p {
        font-size: 1rem; // Размер текста описания
        color: #555; // Цвет текста описания
      }
    }
  }
}

// Медиа-запросы для адаптивности
@media (max-width: 1024px) {
  .service-list {
    padding: 3rem 1rem; // Уменьшение отступов

    h2 {
      font-size: 2.2rem; // Уменьшение размера заголовка
    }

    .service-cards {
      .service-card {
        width: 250px; // Уменьшение ширины карточки
        padding: 1.5rem; // Уменьшение отступов внутри карточки
      }
    }
  }
}

@media (max-width: 768px) {
  .service-list {
    padding: 2rem 1rem; // Уменьшение отступов

    h2 {
      font-size: 1.8rem; // Уменьшение размера заголовка
    }

    .service-cards {
      .service-card {
        width: 100%; // Ширина карточек на 100% для мобильных
        margin: 0.5rem 0; // Уменьшение отступов между карточками
      }
    }
  }
}

@media (max-width: 480px) {
  .service-list {
    padding: 1rem; // Уменьшение отступов

    h2 {
      font-size: 1.6rem; // Уменьшение размера заголовка
    }

    .service-cards {
      .service-card {
        padding: 1rem; // Уменьшение отступов внутри карточки
      }
    }
  }
}
