.advantages {
  padding: 4rem 2rem; // Отступы сверху и снизу
  background-color: #f9f9f9; // Светлый фон
  text-align: center; // Центрирование текста
  border-radius: 15px; // Закругленные углы
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); // Тень для эффекта

  h2 {
    font-size: 2.5rem; // Размер заголовка
    color: #3498db; // Цвет заголовка
    margin-bottom: 2rem; // Отступ снизу
    text-transform: uppercase; // Заглавные буквы
  }

  ul {
    display: flex; // Используем flexbox для горизонтального расположения
    flex-wrap: wrap; // Позволяет элементам переходить на следующую строку
    justify-content: center; // Центрируем элементы
    list-style-type: none; // Убираем маркеры списка
    padding: 0; // Убираем отступы
  }

  .advantage-item {
    flex: 1 1 30%; // Ширина каждого элемента
    margin: 1rem; // Отступ между элементами
    padding: 2rem; // Внутренние отступы
    background-color: #fff; // Белый фон для элементов
    border: 1px solid #3498db; // Граница
    border-radius: 10px; // Закругленные углы
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); // Тень
    transition: transform 0.3s, box-shadow 0.3s; // Плавный переход для эффектов

    &:hover {
      transform: translateY(-5px); // Поднимаем элемент вверх при наведении
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); // Увеличиваем тень
    }

    .advantage-icon {
      font-size: 3rem; // Размер иконок
      color: #3498db; // Цвет иконок
      margin-bottom: 1rem; // Отступ снизу
    }

    h3 {
      font-size: 1.5rem; // Размер заголовка элемента
      color: #333; // Цвет заголовка
      margin: 1rem 0; // Отступы
    }

    p {
      font-size: 1rem; // Размер описания
      color: #555; // Цвет описания
      line-height: 1.5; // Межстрочный интервал
    }
  }
}

// Медиа-запросы для адаптивности
@media (max-width: 1024px) {
  .advantages {
    padding: 3rem 1rem; // Уменьшение отступов

    h2 {
      font-size: 2.2rem; // Уменьшение размера заголовка
    }

    .advantage-item {
      flex: 1 1 45%; // Изменение ширины для более крупных экранов
    }
  }
}

@media (max-width: 768px) {
  .advantages {
    padding: 2rem 1rem; // Уменьшение отступов

    h2 {
      font-size: 1.8rem; // Уменьшение размера заголовка
    }

    .advantage-item {
      flex: 1 1 100%; // Ширина карточек на 100% для мобильных
      margin: 0.5rem 0; // Уменьшение отступов между элементами
    }
  }
}

@media (max-width: 480px) {
  .advantages {
    padding: 1rem; // Уменьшение отступов

    h2 {
      font-size: 1.6rem; // Уменьшение размера заголовка
    }

    .advantage-item {
      padding: 1.5rem; // Уменьшение отступов внутри карточки
    }
  }
}
