.contact-page {
    padding: 40px 20px;
    background-color: #f9f9f9;
  }
  
  .contact-header {
    text-align: center;
    margin-bottom: 40px;
  
    h1 {
      font-size: 2.5rem;
      color: #3498db;
    }
  
    p {
      font-size: 1.2rem;
      color: #666;
    }
  }
  
  .contact-info {
    text-align: center;
    h2 {
      font-size: 2rem;
      color: #333;
    }
  
    p {
      font-size: 1.2rem;
      color: #555;
      margin: 5px 0;
    }
  }
  