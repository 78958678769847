.footer {
  width: 100%;
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(90deg, #e0f7fa, #ffecb3);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  color: #333;

  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1200px;

    .social-icons {
      display: flex;
      margin-bottom: 20px;

      ul {
        display: flex;
        padding: 0;

        li {
          list-style: none;
          margin: 0 15px;

          a {
            font-size: 30px;
            color: #333;
            transition: transform 0.3s, color 0.3s;

            &:hover {
              color: #007BFF;
              transform: scale(1.1);
            }
          }
        }
      }
    }

    .footer-menu {
      display: flex;
      flex-wrap: wrap; // Адаптивный перенос при малых экранах
      justify-content: center; // Центрирование меню
      margin-bottom: 25px;

      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0;

        li {
          list-style: none;
          margin: 0 10px; // Меньший отступ для компактности на мобильных экранах

          a {
            text-decoration: none;
            color: #333;
            font-size: 18px;
            padding: 10px 15px;
            border-radius: 5px;
            transition: background 0.3s, color 0.3s;

            &:hover {
              background: rgba(0, 123, 255, 0.1);
              color: #007BFF;
            }
          }
        }
      }
    }

    .footer-info {
      text-align: center;
      font-size: 14px;
      color: #666;

      p {
        margin: 5px 0;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          margin-right: 8px;
        }
      }
    }
  }

  .footer-text {
    color: #333;
    text-align: center;
    margin-top: 15px;
    font-size: 12px;
    font-style: italic;
  }

  // Адаптивные стили для различных экранов
  @media (max-width: 1024px) {
    padding: 30px 30px;

    .footer-menu ul li {
      margin: 0 8px; // Немного уменьшенные отступы
    }
  }

  @media (max-width: 768px) {
    padding: 20px 15px;

    .footer-content {
      .social-icons {
        margin-bottom: 15px;

        ul li {
          margin: 0 10px;

          a {
            font-size: 24px;
          }
        }
      }

      .footer-menu ul li a {
        font-size: 16px;
        padding: 8px 12px;
      }

      .footer-info {
        font-size: 12px;
      }
    }
  }

  @media (max-width: 480px) {
    padding: 15px 10px;

    .footer-content {
      .social-icons ul li {
        margin: 0 5px;

        a {
          font-size: 22px;
        }
      }

      .footer-menu {
        flex-wrap: wrap; // Позволяет меню переноситься на следующую строку на узких экранах
        gap: 10px; // Дополнительное пространство между пунктами на маленьких экранах

        ul {
          flex-wrap: wrap; // Перенос строк для мобильных
          justify-content: center; // Центрирование на маленьких экранах
          gap: 5px;

          li {
            margin: 5px 0;
          }

          a {
            font-size: 14px;
            padding: 6px 10px;
          }
        }
      }

      .footer-info {
        font-size: 11px;
      }
    }

    .footer-text {
      font-size: 10px;
    }
  }
}
