.about-values {
    padding: 60px 20px;
    text-align: center;
    background: linear-gradient(45deg, rgba(224, 247, 250, 0.9), rgba(255, 236, 179, 0.9));
    background-size: 200% 200%;
    animation: gradientAnimation 10s ease infinite; // Анимация градиента
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
  
    &__title {
      font-size: 2.5rem;
      color: #fff;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-bottom: 20px;
    }
  
    &__description {
      font-size: 1.2rem;
      color: #fff;
      max-width: 800px;
      margin: 0 auto 40px;
    }
  
    &__items {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 30px;
  
      .value-item {
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 10px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
        padding: 20px;
        transition: transform 0.3s, box-shadow 0.3s;
  
        &:hover {
          transform: translateY(-5px);
          box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
        }
  
        &__icon {
          font-size: 3rem;
          color: #FF6F61;
          margin-bottom: 10px;
        }
  
        &__title {
          font-size: 1.5rem;
          color: #333;
          margin-bottom: 10px;
        }
  
        &__description {
          font-size: 1rem;
          color: #777;
        }
      }
    }
  }
  
  // Медиа-запросы для адаптивности
  @media (max-width: 1024px) {
    .about-values {
      padding: 50px 15px;
  
      &__title {
        font-size: 2.2rem;
      }
  
      &__description {
        font-size: 1.1rem;
      }
  
      &__items .value-item {
        .value__icon {
          font-size: 2.8rem;
        }
  
        .value__title {
          font-size: 1.4rem;
        }
  
        .value__description {
          font-size: 0.95rem;
        }
      }
    }
  }
  
  @media (max-width: 768px) {
    .about-values {
      padding: 40px 10px;
  
      &__title {
        font-size: 2rem;
      }
  
      &__description {
        font-size: 1rem;
      }
  
      &__items .value-item {
        padding: 15px;
  
        &__icon {
          font-size: 2.5rem;
        }
  
        &__title {
          font-size: 1.3rem;
        }
  
        &__description {
          font-size: 0.9rem;
        }
      }
    }
  }
  
  @media (max-width: 480px) {
    .about-values {
      padding: 30px 8px;
  
      &__title {
        font-size: 1.8rem;
      }
  
      &__description {
        font-size: 0.95rem;
      }
  
      &__items {
        grid-template-columns: 1fr;
      }
  
      &__items .value-item {
        width: 100%;
        padding: 10px;
  
        &__icon {
          font-size: 2.2rem;
        }
  
        &__title {
          font-size: 1.2rem;
        }
  
        &__description {
          font-size: 0.85rem;
        }
      }
    }
  }
  
  // Анимация для градиента
  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  