.about-header {
    padding: 60px 20px;
    text-align: center;
    background: linear-gradient(45deg, rgba(224, 247, 250, 0.9), rgba(255, 236, 179, 0.9));
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
  
    &__title {
      font-size: 2.5rem;
      margin-bottom: 20px;
      color: #333;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  
    &__description {
      font-size: 1.2rem;
      margin-bottom: 40px;
      color: #666;
    }
  
    &__quote {
      font-style: italic;
      color: #999;
      margin: 20px 0;
      font-size: 1.1rem;
    }
  
    &__icons {
      display: flex;
      justify-content: center;
      gap: 30px;
      margin: 40px 0;
      flex-wrap: wrap;
  
      .icon {
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
        padding: 20px;
        transition: transform 0.3s, box-shadow 0.3s;
  
        &:hover {
          transform: translateY(-5px);
          box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
        }
  
        svg {
          font-size: 3rem;
          margin-bottom: 10px;
          color: #3498db;
        }
  
        p {
          margin-top: 5px;
          font-size: 1rem;
          color: #444;
        }
      }
    }
  }
  
  // Адаптивные стили для различных размеров экранов
  @media (max-width: 1024px) {
    .about-header {
      padding: 50px 20px;
  
      &__title {
        font-size: 2.2rem;
      }
  
      &__description {
        font-size: 1.1rem;
      }
  
      &__icons .icon {
        padding: 15px;
  
        svg {
          font-size: 2.8rem;
        }
  
        p {
          font-size: 0.95rem;
        }
      }
    }
  }
  
  @media (max-width: 768px) {
    .about-header {
      padding: 40px 15px;
  
      &__title {
        font-size: 2rem;
      }
  
      &__description {
        font-size: 1rem;
        margin-bottom: 30px;
      }
  
      &__icons {
        gap: 20px;
        .icon {
          padding: 15px;
  
          svg {
            font-size: 2.5rem;
          }
  
          p {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
  
  @media (max-width: 480px) {
    .about-header {
      padding: 30px 10px;
      
      &__title {
        font-size: 1.8rem;
        margin-bottom: 15px;
      }
  
      &__description {
        font-size: 0.95rem;
      }
  
      &__quote {
        font-size: 1rem;
        margin: 15px 0;
      }
  
      &__icons {
        gap: 15px;
  
        .icon {
          padding: 10px;
  
          svg {
            font-size: 2.2rem;
          }
  
          p {
            font-size: 0.85rem;
          }
        }
      }
    }
  }
  
  @media (max-width: 360px) {
    .about-header {
      padding: 25px 8px;
  
      &__title {
        font-size: 1.6rem;
      }
  
      &__description,
      &__quote {
        font-size: 0.9rem;
      }
  
      &__icons {
        gap: 10px;
  
        .icon {
          padding: 8px;
  
          svg {
            font-size: 2rem;
          }
  
          p {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
  