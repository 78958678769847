.price-list {
  padding: 3rem 2rem; // Отступы
  background: linear-gradient(to bottom right, #e0f7fa, #fff); // Градиент
  border-radius: 15px; // Закругленные углы
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2); // Тень
  text-align: center; // Центрирование текста
  margin: 2rem 0; // Отступы сверху и снизу
  animation: fadeIn 0.5s; // Анимация появления

  h2 {
    font-size: 2.5rem; // Размер заголовка
    color: #00796b; // Цвет заголовка
    margin-bottom: 1.5rem; // Отступ снизу;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); // Тень текста
  }

  h3 {
    font-size: 1.8rem; // Размер подзаголовка
    color: #004d40; // Цвет подзаголовка
    margin: 2rem 0 1rem; // Отступы
    text-decoration: underline; // Подчеркивание
    text-decoration-color: #00796b; // Цвет подчеркивания
  }

  table {
    width: 100%; // Ширина таблицы
    border-collapse: collapse; // Убираем промежутки между ячейками
    margin-bottom: 3rem; // Отступ снизу

    th,
    td {
      padding: 1rem; // Внутренние отступы
      border: 1px solid #00796b; // Граница
      text-align: left; // Выравнивание текста
      font-size: 1.1rem; // Размер шрифта
      transition: background-color 0.3s ease; // Плавный переход
    }

    th {
      background-color: #00796b; // Фон заголовков
      color: white; // Цвет текста заголовков
      font-weight: bold; // Жирный текст
    }

    tr:nth-child(even) {
      background-color: #b2ebf2; // Фон для четных строк
    }

    tr:hover {
      background-color: #80deea; // Фон при наведении
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

// Медиа-запросы для адаптивности
@media (max-width: 1024px) {
  .price-list {
    padding: 2rem 1rem; // Уменьшение отступов

    h2 {
      font-size: 2.2rem; // Уменьшение размера заголовка
    }

    h3 {
      font-size: 1.6rem; // Уменьшение размера подзаголовка
    }

    table {
      font-size: 1rem; // Уменьшение размера шрифта таблицы
    }
  }
}

@media (max-width: 768px) {
  .price-list {
    padding: 1.5rem 0.5rem; // Уменьшение отступов

    h2 {
      font-size: 2rem; // Уменьшение размера заголовка
    }

    h3 {
      font-size: 1.4rem; // Уменьшение размера подзаголовка
    }

    table {
      font-size: 0.9rem; // Уменьшение размера шрифта таблицы

      th,
      td {
        padding: 0.8rem; // Уменьшение отступов в ячейках
      }
    }
  }
}

@media (max-width: 480px) {
  .price-list {
    padding: 1rem; // Уменьшение отступов

    h2 {
      font-size: 1.8rem; // Уменьшение размера заголовка
    }

    h3 {
      font-size: 1.2rem; // Уменьшение размера подзаголовка
    }

    table {
      font-size: 0.8rem; // Уменьшение размера шрифта таблицы

      th,
      td {
        padding: 0.5rem; // Уменьшение отступов в ячейках
      }
    }
  }
}
