.service-intro {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh; // Высота секции оставлена прежней
  background-color: #f0f4f8; // Светлый фон
  color: #333;
  text-align: center;
  padding: 0 15px; // Уменьшены боковые отступы
  margin-top: 60px; // Добавьте верхний отступ для разделения с заголовком
  margin-bottom: 20px; // Добавьте нижний отступ для разделения с нижним компонентом

  .content {
    max-width: 600px; // Уменьшена максимальная ширина
    padding: 2rem; // Уменьшены отступы
    border: 2px solid #3498db; // Граница с цветом компании
    border-radius: 15px; // Закругленные углы
    background-color: #ffffff; // Белый фон
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); // Легкая тень

    h1 {
      font-size: 2rem; // Уменьшен размер заголовка
      margin-bottom: 0.5rem; // Уменьшение отступа снизу
      color: #3498db; // Цвет заголовка
      font-weight: bold; // Жирный
      text-transform: uppercase; // Верхний регистр
      letter-spacing: 0.5px; // Меньшее расстояние между буквами
    }

    p {
      font-size: 1rem; // Уменьшен размер текста
      line-height: 1.5; // Межстрочный интервал
      margin-bottom: 1rem; // Уменьшение отступа снизу
      color: #555; // Цвет текста
      background-color: #f9f9f9; // Фон для текста
      padding: 0.5rem; // Отступы внутри текста
      border-radius: 8px; // Закругленные углы для текста
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05); // Легкая тень

      &.discount {
        color: #e74c3c; // Красный цвет для скидки
        font-weight: bold; // Жирный текст
        background-color: #fff3cd; // Светлый фон для выделения
        padding: 1rem; // Увеличенные отступы для выделенного текста
        border: 1px solid #e67e22; // Граница оранжевого цвета
        border-radius: 8px; // Закругленные углы
        margin-bottom: 0; // Убираем отступ снизу
      }
    }
  }
}

// Нижний компонент
.lower-component { // Замените на класс вашего нижнего компонента
  margin-top: 20px; // Убедитесь, что есть достаточно пространства между компонентами

}

// Медиа-запросы для адаптивности
@media (max-width: 1024px) {
  .service-intro {
    height: 50vh; // Уменьшение высоты секции

    .content {
      max-width: 90%; // Увеличение ширины на меньших экранах
      padding: 1.5rem; // Уменьшение отступов
    }

    h1 {
      font-size: 1.8rem; // Уменьшение размера заголовка
    }

    p {
      font-size: 0.95rem; // Уменьшение размера текста
    }
  }
}

@media (max-width: 768px) {
  .service-intro {
    height: 40vh; // Уменьшение высоты секции

    .content {
      max-width: 95%; // Почти полная ширина
      padding: 1rem; // Уменьшение отступов

      h1 {
        font-size: 1.6rem; // Уменьшение размера заголовка
      }

      p {
        font-size: 0.9rem; // Уменьшение размера текста
      }
    }
  }
}

@media (max-width: 480px) {
  .service-intro {
    height: 35vh; // Уменьшение высоты секции
    margin-bottom: 30%;
    margin-top: 30%;

    .content {
      max-width: 100%; // Полная ширина
      padding: 0.5rem; // Уменьшение отступов

      h1 {
        font-size: 1.4rem; // Уменьшение размера заголовка
      }

      p {
        font-size: 0.85rem; // Уменьшение размера текста
      }
    }
  }
}
