.services {
    padding: 60px 20px; // Увеличенное значение отступов
    background: linear-gradient(to right, #f0f8ff, #e6f7ff); // Градиентный фон
    text-align: center;
    position: relative;
  
    &__title {
      font-size: 2.5rem;
      margin-bottom: 20px;
      color: #333; // Темный цвет для заголовка
      text-transform: uppercase; // Заглавные буквы для стиля
      letter-spacing: 1px; // Расстояние между буквами
    }
  
    &__description {
      font-size: 1.2rem;
      margin-bottom: 40px;
      color: #666;
    }
  
    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 30px; // Увеличенное значение отступов между услугами
    }
  
    &__additional-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 30px; // Увеличенное значение отступов между дополнительными услугами
      margin-top: 20px; // Отступ сверху для дополнительных услуг
    }
  
    .service {
      background-color: #ffffff; // Белый фон для услуги
      border-radius: 10px; // Скругленные углы
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); // Более глубокая тень
      padding: 20px;
      width: 280px; // Ширина карточки услуги
      transition: transform 0.3s, box-shadow 0.3s; // Плавные эффекты при наведении
      position: relative; // Позиционирование для псевдоэлементов
  
      &:hover {
        transform: translateY(-5px); // Эффект поднятия при наведении
        box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2); // Увеличение тени
      }
  
      &__icon {
        font-size: 3rem; // Размер иконки
        margin-bottom: 10px;
      }
  
      &__title {
        font-size: 1.5rem;
        margin: 10px 0;
        color: #333; // Темный цвет заголовка услуги
      }
  
      &__description {
        font-size: 1rem;
        color: #777;
      }
    }
  
    &__additional-button {
      background-color: #007bff; // Цвет кнопки
      color: white; // Цвет текста кнопки
      border: none; // Без рамки
      padding: 10px 20px; // Отступы
      border-radius: 5px; // Скругление углов
      cursor: pointer; // Указатель при наведении
      font-size: 1rem;
      margin-top: 20px; // Отступ сверху
  
      &:hover {
        background-color: #0056b3; // Более темный цвет при наведении
      }
    }
  }
  