.header {
  background: linear-gradient(90deg, #2c3e50, #34495e); // Градиент фона
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
  animation: fadeIn 0.5s ease-in-out;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 5%;
    max-width: 1200px;
    margin: 0 auto;
  }

  &__logo {
    display: flex;
    align-items: center; // Центрируем логотип и текст
    img {
      width: 50px; // Размер логотипа
      height: auto; // Автоматическая высота
      margin-right: 10px; // Отступ между логотипом и текстом
      filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5)); // Тень для логотипа
    }
    
    a {
      font-size: 2rem; // Увеличиваем размер текста
      font-weight: bold;
      color: #ecf0f1;
      text-decoration: none;
      transition: color 0.3s ease;

      &:hover {
        color: #008cba;
      }
    }
  }

  &__menu-toggle {
    display: none;

    .menu-icon {
      color: #ecf0f1;
      font-size: 2rem; // Увеличиваем размер иконки
      transition: color 0.3s ease;

      &:hover {
        color: #008cba; // Цвет при наведении
      }
    }
  }

  &__nav {
    display: flex;
    align-items: center;

    &.open {
      display: block;
    }
  }

  &__nav-list {
    display: flex;
    gap: 30px;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      a {
        text-decoration: none;
        font-size: 1.2rem; // Увеличиваем размер ссылок
        color: #bdc3c7;
        transition: color 0.3s ease, text-shadow 0.3s ease; // Добавляем плавный переход для тени

        &:hover {
          color: #008cba;
          text-shadow: 0 0 10px rgba(0, 140, 186, 0.5); // Тень при наведении
        }

        &.active {
          color: #008cba;
          font-weight: bold;
          border-bottom: 2px solid #008cba;
        }
      }
    }
  }

  &__nav-item {
    position: relative;

    &.active a {
      color: #008cba;
      font-weight: bold;
    }
  }
}

// Мобильная адаптивность
@media (max-width: 768px) {
  .header__menu-toggle {
    display: block;
  }

  .header__nav {
    overflow: hidden;
    max-height: 0; // Начальная высота
    transition: max-height 0.3s ease;

    &.open {
      max-height: 500px; // Максимальная высота
      display: block;
    }

    .header__nav-list {
      flex-direction: column;
      gap: 15px;
    }
  }

  .header__logo {
    img {
      display: none;
    }
  }
}

// Плавная анимация появления
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
