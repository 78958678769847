@import '~@fortawesome/fontawesome-free/css/all.css';

html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden; // Убирает горизонтальный скролл
    width: 100%;

}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
